<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    sizeX: {
      type: [Number, String],
      default: 24,
    },
    sizeY: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="sizeX"
    :height="sizeY"
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6707 0H8.69296V24H10.6707C12.1098 23.9714 13.4788 23.3735 14.4779 22.3374C15.477 21.3013 16.0246 19.9113 16.0007 18.4722V17.5328H11.7684C11.4746 17.5328 11.1929 17.4161 10.9852 17.2084C10.7775 17.0007 10.6608 16.719 10.6608 16.4252C10.6608 16.1315 10.7775 15.8498 10.9852 15.6421C11.1929 15.4344 11.4746 15.3177 11.7684 15.3177H16.0007V13.1125H11.7684C11.4746 13.1125 11.1929 12.9958 10.9852 12.7881C10.7775 12.5804 10.6608 12.2987 10.6608 12.0049C10.6608 11.7112 10.7775 11.4295 10.9852 11.2218C11.1929 11.0141 11.4746 10.8974 11.7684 10.8974H16.0007V8.68232H11.7684C11.4939 8.65631 11.2391 8.5289 11.0536 8.325C10.8681 8.1211 10.7654 7.85537 10.7654 7.57973C10.7654 7.30409 10.8681 7.03835 11.0536 6.83445C11.2391 6.63056 11.4939 6.50315 11.7684 6.47713H16.0007V5.48826C16.0141 4.05591 15.4618 2.67613 14.4638 1.64856C13.4659 0.620987 12.1028 0.0285864 10.6707 0ZM0.000745445 5.5377V18.4722C-0.0230697 19.913 0.525893 21.3044 1.52709 22.3408C2.52829 23.3773 3.89987 23.974 5.34067 24H7.31842V0H5.34067C3.89898 0.0285901 2.52741 0.627497 1.52649 1.66549C0.525561 2.70349 -0.0230911 4.09592 0.000745445 5.5377Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
